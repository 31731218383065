import React from "react";
import Popup from "../ui/popup/Popup.amp";
import {STRINGS} from "../../constants/strings";

function SubscribePopup(props) {
    const formId = "subscriptionForm";
    const loaderId = "loading";

    return (
        <Popup customClass={''} elementId={props.elementId} formId={formId} loaderId={loaderId} containerClass={'subscribe-popup ebook-popup bg-denim-300 rounded-2xl'} data-datocms-noindex closeIcon={true}>
            <div className={"h-full relative "}>
                <div className={"close-icon bg-denim-300 absolute rounded-full text-3xl text-white font-bold text-center cursor-pointer"} tabindex="0" role="button" on={`tap:${loaderId}.hide,${formId}.clear,${props.elementId}.hide`}>X</div>
                <div className={"bg-subscribe-popup w-full min-h-screen-40 bg-cover bg-center rounded-t-2xl"}/>
                <div className={"px-4 text-white"}>
                    <div className={"text-xl mt-2 mb-1 uppercase text-center font-bold leading-snug font-montserrat"}>
                        Stay on Top of the News!
                    </div>
                    <div className={"text-lg italic text-center leading-snug font-arial mb-2"} style={{fontSize: '15px'}}>
                        Subscribe to our newsletter and receive the latest articles directly in your mailbox
                    </div>
                    <form method="GET"
                          on="submit-success:loading.hide,subscription.hide;submit-error:loading.hide;invalid:loading.hide"
                          id="subscriptionForm"
                          action-xhr="https://www.age.holdings/api/subscribe" action="/" target="_top"
                          custom-validation-reporting="show-first-on-submit">
                        <div className={"xs:my-2"}>
                            <input type="hidden" id="website" name="website" value={STRINGS.SUBSCRIPTION_TAG}/>
                            <div className={'error-message'} validation-for="name"
                                 visible-when-invalid="valueMissing">Please enter valid name
                            </div>
                            <div className={'error-message'} validation-for="email"
                                 visible-when-invalid="valueMissing">Please enter valid email
                            </div>
                            <div className={'error-message'} validation-for="email"
                                 visible-when-invalid="patternMismatch">Please enter valid email
                            </div>
                            <div className={'error-message'} validation-for="tnc"
                                 visible-when-invalid="valueMissing">Please accept Pension Times’ Terms and Privacy
                                Policy
                            </div>
                            <input type="text"
                                   id="name"
                                   name="name"
                                   className={"popup-input mb-2"}
                                   placeholder="Enter your first name*"
                                   required/>
                            <input type="email"
                                   id="email"
                                   name="email"
                                   className={"popup-input mb-2"}
                                   placeholder="Enter your email*"
                                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required/>
                        </div>
                        <div className={'flex items-center italic font-arial leading-snug'} style={{fontSize: '15px'}}>
                            <input type="checkbox" id="tnc" name="tnc" value="true" required/>
                            <label htmlFor="tnc">
                                I accept and acknowledge Pension Times’ <a href="/website-terms-of-use" target="_blank"
                                                                           className={"underline"}>
                                Terms</a> and <a href="/website-privacy-policy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>
                        <div className={'flex items-center italic font-arial leading-snug'} style={{fontSize: '15px'}}>
                            <input type="checkbox" id="marketing" value="true" name="marketing"/>
                            <label htmlFor="marketing">
                                I’m happy for Pension Times to send me selected marketing information which may be of
                                interest to me.
                            </label>
                        </div>
                        <div className={'font-helvetica text-xl'}>
                            <button on={`tap:loading.hide,${props.elementId}.hide,subscriptionForm.clear`}
                                    className={'bg-denim-500 flex-1 rounded-lg py-3 mr-4 mb-2 w-full subscribe-button-no'}>No,
                                Thanks
                            </button>
                            <button type="submit" on="tap:loading.show"
                                    className={'subscribe-button-yes ok bg-yellow-400 text-denim-500 flex-1 rounded-lg py-3 mb-2 w-full'}>
                                I’m Ready
                                <img hidden id="loading" src="/images/loading.svg"
                                     style={{ marginLeft: "5px", width: '32px', height: '32px' }}
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Popup>
    );
}

export default SubscribePopup
