import React from 'react'
import {withPrefix} from 'gatsby'
import Helmet from 'react-helmet'
import favicon from '../../resources/favicon.png'
import {metadata} from '../../../config.js';
import {isMobile} from "react-device-detect";
import HeaderWrapperAMP from "../header/index.amp";
import FooterAMPWrapper from "../footer/index.amp";
import ShareAmp from "../share/sharing.amp";
import {delayMultiplicationFactor, googleScriptLoadingDelay} from "../../utils/articleUtil";
import consts from "../../constants/consts";

import "../../css/index.scss";
import SubscribePopup from "../subscribe/SubscribePopup.amp";

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryPath: this.categoryPath,
            subCategoryPath: this.subCategoryPath,
            timeouts: [],
            displayCookie: false,
            timeoutMultiplier: 1
        }
    }

    componentDidMount() {
        const headElement = document.getElementsByTagName("head")[0];

        const _this = this;
        const timeoutMultiplier = delayMultiplicationFactor(isMobile);
        this.setState({timeoutMultiplier})

        this.state.timeouts.push(setTimeout(() => {
            this.addAccessibilityScript();
        }, consts.SCRIPT_LOADING_TIME_WAIT * 4.5 * timeoutMultiplier));

        this.state.timeouts.push(setTimeout(() => {
            if (!document.querySelector('script[src*="osd.js"]')) {
                const googleActiveViewScript = document.createElement("script");
                googleActiveViewScript.src = `https://www.googletagservices.com/activeview/js/current/osd.js?cb=%2Fr20100101`;
                headElement.appendChild(googleActiveViewScript);
            }

            if (!document.querySelector('script[src*="gtm"]')) {
                const gtmScript = document.createElement("script");
                gtmScript.src = `${withPrefix(`gtm.js`)}`;
                gtmScript.async = true;
                headElement.appendChild(gtmScript);
            }

            if (!document.querySelector('script[src*="gpt.js"]')) {
                _this.addGPTScript();
            } else if (!document.querySelector('script[src*="ad_2.0.1.js"]')) {
                _this.addAdScript();
            } else {
                _this.refreshAd();
            }
        }, googleScriptLoadingDelay() * timeoutMultiplier));
    }

    addGPTScript() {
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `https://securepubads.g.doubleclick.net/tag/js/gpt.js`;
        adScript.async = true;
        headElement.appendChild(adScript);
        this.addAdScript();
    }

    addAdScript() {
        const _this = this;
        const headElement = document.getElementsByTagName("head")[0];
        const adScript = document.createElement("script");
        adScript.src = `${withPrefix('ad_2.0.1.js')}`;
        adScript.type = "text/javascript";
        headElement.appendChild(adScript);
        adScript.onload = () => {
            this.state.timeouts.push(setTimeout(() => {
                _this.refreshAd();
            }, 200 * this.state.timeoutMultiplier));
        }
    }

    refreshAd() {
        this.state.timeouts.push(setTimeout(() => {
            try {
                window.googletag.pubads().refresh(null, {changeCorrelator: true});
            } catch (e) {
                console.log('googletag: ' + e);
            }
        }, 100));
    }

    addAccessibilityScript() {
        const accessibilityOptions = {
            animations: {buttons: false},
            modules: {
                increaseText: true,
                decreaseText: true,
                invertColors: [true / false],
                increaseTextSpacing: false,
                decreaseTextSpacing: false,
                grayHues: true,
                underlineLinks: false,
                bigCursor: true,
                readingGuide: true,
                textToSpeech: true,
                speechToText: true
            },
            icon: {
                useEmojis: true
            }
        }
        const headElement = document.getElementsByTagName("head")[0];
        const _this = this;
        if (!document.querySelector('script[src*="accessibility.min.js"]')) {
            const script = document.createElement("script");
            script.src = `${withPrefix('accessibility.min.js')}`;
            script.type = "text/javascript";
            headElement.appendChild(script);
            script.onload = () => {
                _this.state.timeouts.push(setTimeout(() => {
                    new Accessibility(accessibilityOptions);
                }, 1000));
            }
        }
    }

    componentWillUnmount() {
        this.state.timeouts.forEach(timeout => clearTimeout(timeout));
    }

    isBrowser = () => typeof window !== "undefined";

    categoryPath = () => {
        return this.isBrowser() && window.location.pathname.split('/')[1];
    }

    subCategoryPath = () => {
        if (this.isBrowser()) {
            const pathArray = window.location.pathname.split('/');
            if (pathArray && pathArray[2]) {
                return pathArray[2];
            }
        }
        return false;
    }

    isProduction = () => {
        return this.isBrowser() && ['www.pensiontimes.co.uk', 'pensiontimes.co.uk'].includes(window.location.host);
    }


    render() {
        return (
            <React.Fragment data-datocms-noindex>
                <Helmet defaultTitle={metadata.defaultTitle} titleTemplate={metadata.titleTemplate} htmlAttributes={{
                    amp: `true`,
                    lang: 'en'
                }}>
                    <meta name="author" content={metadata.author}/>
                    <meta name="description" content={metadata.description}/>
                    <meta name="keywords" content={metadata.keywords}/>
                    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                    <meta httpEquiv="Content-Language" content="en"/>
                    <meta property={metadata.facebookId} content="Pension-Times-103096144798202"/>
                    <meta property={metadata.twitterId} content="PensionTimes"/>
                    <meta name="amp-consent-blocking" content="amp-ad" />
                    <link title="timeline-styles" rel="shortcut icon" type="image/png" href={favicon}/>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Prata&family=Montserrat:wght@600&display=swap"/>
                </Helmet>
                <div id="outer-container" data-datocms-noindex>
                    <amp-consent id="consent" layout="nodisplay" type="onetrust">
                        <script type="application/json"  dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "postPromptUI": "onetrust-consent-prompt-ui",
                                "clientConfig": {
                                    "CMP_id": "db57f6d6-302a-4db6-9f29-1f0b4bf8b1e1",
                                    "Env": "App",
                                    "Opt_Out": "C0004"
                                }
                            })
                            }}>
                        </script>
                        <div id="onetrust-consent-prompt-ui">
                            <button on="tap:consent.prompt(consent=onetrust)" id="consent-popup">
                               <svg id="consent-svg" role="presentation" xmlns="http://www.w3.org/2000/svg"><path class="ot-floating-button__svg-fill" d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z" fill="#FFF" fill-rule="evenodd"></path></svg>
                            </button>
                        </div>
                    </amp-consent>
                    <HeaderWrapperAMP path={this.props.path} categoryPath={this.state.categoryPath()} subCategoryPath={this.state.subCategoryPath()}/>
                    {this.props.children}
                    <FooterAMPWrapper categoryPath={this.state.categoryPath()} subCategoryPath={this.state.subCategoryPath()}/>
                    <div hidden>
                        <ShareAmp />
                    </div>
                    <div hidden>
                        <amp-facebook-comments width="486" height="657" layout="responsive" data-href="https://wwww.pensiontimes.co.uk">
                        </amp-facebook-comments>
                    </div>
                    <div id="subscription" hidden>
                        <SubscribePopup elementId={"subscription"} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Layout
