import React from "react";
import cx from "classnames";

function Popup(props) {
    const className = cx(
        "overlay-container",
        props.customClass,
    );

    const containerClass = cx(
        props.containerClass ? props.containerClass : "popup-container"
    );

    const closeIconClass = cx(
        "close-icon",
        props.closeIconClass
    );

    const id = props.id || `popup-${Math.random()}`;

    return (
        <div id={id} className={className}>
            <div className={'overlay'} tabindex="0" role="button" on={`tap:${props.loaderId}.hide,${props.formId}.clear,${props.elementId}.hide`}></div>
            <div className={containerClass}>
                {!props.closeIcon && <div className={closeIconClass} tabindex="0" role="button" on={`tap:${props.loaderId}.hide,${props.formId}.clear,${props.elementId}.hide`}><span>X</span></div>}
                {props.children}
            </div>
        </div>
    );
}

export default Popup
